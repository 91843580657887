import React from "react";
import Layout from "../../components/global/layout";
import GenericHeader from "../../components/templates/genericHeader";
import GenericBody from "../../components/templates/genericBody";
import GetInTouchToday from "../../components/global/GetInTouchToday";
import SEO from "../../components/global/seo";
import { graphql, StaticQuery } from "gatsby";
import DynamicsCrmImage from "../../assets/solutions/microsoft-dynamics-crm-header.png";
import ReactMarkdown from "react-markdown";

export default () => (
    <StaticQuery
        query={graphql`
      query DigitalmarketingQuery {
        allStrapiDigitalmarketingpage {
          edges {
            node {
              HeaderHeading
              HeaderInfo
              Body
              MetaDescription
            }
          }
        }
      }
    `}
        render={data => (
            <Layout>
                {data.allStrapiDigitalmarketingpage.edges.map(document => (
                    <SEO
                        title="Digital Marketing"
                        keywords={[
                            'Digital Marketing',
                            `Digital Solutions`,
                            `Social Media`,
                            `Assets`
                        ]}
                        description={document.node.MetaDescription}
                    />
                ))}


                <GenericHeader
                    Heading={data.allStrapiDigitalmarketingpage.edges.map(document => (
                        <div>{document.node.HeaderHeading}</div>
                    ))}
                    headerInfo={data.allStrapiDigitalmarketingpage.edges.map(document => (
                        <div>{document.node.HeaderInfo}</div>
                    ))}
                    infoHeaderImage={DynamicsCrmImage}
                    infoHeaderImageAlt={"Digital Marketing"}
                />
                <GenericBody
                    bodyContent={data.allStrapiDigitalmarketingpage.edges.map(document => (
                        <ReactMarkdown source={document.node.Body}/>
                    ))}
                />
                <GetInTouchToday />
            </Layout>
        )}
    />
);
