import React from "react";
import "./generic.css";
import { Col, Row } from "reactstrap";

const GenericHeader = props => (
    <div className="generic-header">
        <section>
            <div className="container-fluid">
                <Row>
                    <Col lg="8" md="8" sm="12" className="d-flex ylw-bg pt-4">
                        <div className="pl pr m-auto pt-5 pb-5 pt-lg-0 pb-lg-0 w-100">
                            <h2 className="sec-header">{props.Heading}</h2>
                            <div>
                                <p>{props.headerInfo}</p>
                            </div>
                        </div>
                    </Col>
                    <Col  lg={{ size: "4", order: "2" }} md={{ size: "4", order: "2" }} className="d-none d-sm-none d-md-block p-0 order-first">
                        <div className="img-holder">
                            <img
                                src={props.infoHeaderImage}
                                alt={props.infoHeaderImageAlt}
                                width="100%"
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    </div>
);

export default GenericHeader;
