import React from 'react';
import './BoxWidget.css';

export default class BoxWidget extends React.Component {
    render(){
        return(
            <div className="box-widget d-flex">
                <div className="m-auto">
                    <h4 className="text-center">Get in touch today for a free consultation</h4>
                    <p className="mt-3">
                        <a href="/contact" className="valor-btn-chat mx-auto">Let's Chat</a>
                    </p>
                </div>
            </div>
        )
    }
}