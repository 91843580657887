import React from "react";
import BoxWidget from "../global/BoxWidget";

const GenericBody = props => (
  <div className="valor-about">
    <section>
      <div className="container-fluid">
        <div className="d-flex pt-5 pb-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 flex-grow-1 my-auto">
            <div className="pl pr-3 my-auto">
              {props.bodyContent}
            </div>
          </div>
          <div className="my-auto">
            <div className="pl-5 pr">
              <BoxWidget />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default GenericBody;
